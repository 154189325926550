
import _ from 'lodash'
import BasicLayout from '~/layouts/basic.vue'
import { mapWritableState, mapActions, mapState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useIntegrationsStore } from '~/store/integrations'
import { useTemplatesStore } from '~/store/templates'
import { usePricingStore } from "~/store/pricing";
import { usePopsStore } from "~/store/pops";
import { useSiteStore } from '~/store/site';
import { useNewCustomerOnboardingStore } from "~/store/onboardings/newCustomer";
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";

export default {
    components: {
        BasicLayout
    },
    head() {
        return {
            title: 'SalesPop | Pop Library',
        }
    },
    data: ()=>({
        upgradeModalOpen: false,
        intendedProIntegration: null,
        forceReauthOpen: false,
    }),
    async mounted(){
        await this.loadTemplates()
        await this.$track('page_view/pop_library')
        if(this.$route.query.pop) return
        let firstPopToSee = this.availablePops.find(pop => pop.enabled)
        if(!firstPopToSee) firstPopToSee = this.availablePops[0]
        await this.$router.push(firstPopToSee?.route ?? '')

        if (this.onboarding?.currentStep?.id === 'pops_general_settings_back') {
            this.showStepFlag = true;
            this.showOnboardingStep('pops_menu_discounts_button')
        }
    },
    watch: {
        '$route.query.pop': {
            immediate: true,
            handler(pop){
                if(pop) {
                    useUiStore().setTemplateHandler(pop)

                    if(this.$route.query.pop && this.availablePops.find(pop => pop.handle === this.$route.query.pop)){
                        if (this.onboarding?.currentStep?.id === 'pops_rules_and_settings') {
                            this.showStepFlag = true;
                            this.showOnboardingStep('pops_general_settings')
                        }
                        if (this.onboarding?.currentStep?.id === 'button_back_to_pops_settings') {
                            this.showStepFlag = true;
                            this.showOnboardingStep('upsells_edit_sidebar_button')
                        }
                    }
                }
            },
        },
        loadingPreflight: {
            immediate: true,
            async handler(loading){
                if(loading) return
                await this.$track('page_view/pop_library')
            }
        }
    },
    methods: {
        ...mapActions(useTemplatesStore, [
            'toggleTemplate',
            'loadTemplates',
        ]),
        ...mapActions(useSiteStore, [
          'reAuthSiteShop'
        ]),
        ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
        tryToConnect(handle){
            if (this.shouldForceReAuth(this.editingTemplate.handle)) {
              this.forceReauthOpen = true
            } else {
              this.toggleTemplate({handle, value: true})
              this.$router.push({
                  query: {
                      pop: handle
                  }
              })
            }
        },
        handleTemplateToggle(settings) {
            if(this.showUpgradeWithPopCTA(settings)) return
            if(this.shouldForceReAuth(this.editingTemplate.handle)) {
              this.forceReauthOpen = true
            } else {
              this.toggleTemplate({
                  handle: this.editingPopHandler,
                  value: !this.editingTemplate.proof_enabled,
              })
            }
        },
        handleEditPopClick(settings) {
            if(this.showUpgradeWithPopCTA(settings)) return
            this.$router.push(settings)
        },
        showUpgradeWithPopCTA(settings) {
            const pop = this.integrationHandleMap[settings.query.pop]
            if(this.$isPro() || !pop.pro) return false
            this.upgradeModalOpen = true
            this.intendedProIntegration = pop
            return true
        },
        handleAcceptReAuth(){
          this.reAuthSiteShop()
        },
        handleDismissReAuth(){
          this.forceReauthOpen = false
        },
        shouldForceReAuth(handle) {
          let extraScopesPops = ['shopify_low_inventory', 'shopify_discount']
          return this.currentSite.force_auth_scopes_reauth &&
            extraScopesPops.includes(handle) &&
            !this.editingTemplate.proof_enabled
        },
    },
    computed: {
        ...mapState(usePricingStore, ['plan']),
        ...mapState(usePopsStore, ['microExamplePop']),
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(useNewCustomerOnboardingStore, ['onboarding']),
        ...mapWritableState(useNewCustomerOnboardingStore, ['showStepFlag']),
        ...mapWritableState(useUiStore, ['loadingPreflight', 'editingPopHandler']),
        ...mapWritableState(useIntegrationsStore, [
            'sourceIntegrationHandles',
            'integrationHandleMap'
        ]),
        ...mapWritableState(useTemplatesStore, ['editingTemplate', 'templates']),
        availablePops(){
            const mapTemplate = handle => ({
                enabled: this.templatesByHandle?.[handle]?.proof_enabled,
                handle: handle,
                route: `/pops/rules-and-settings?pop=${handle}`,
            })
            return this.sourceIntegrationHandles.map(integration => {
                return {
                    ...integration,
                    ...mapTemplate(integration.integrationHandle),
                }
            })
        },
        templatesByHandle(){
            return _(this.templates)
                .groupBy('handle')
                .mapValues(x => x[0])
                .value()
        },
        settingsRoute(){
            const handle = this.editingTemplate.handle
            const handleDefaultPath = {
                shopify_review: '/pops/rules-and-settings/reviews',
                shopify_discount: '/pops/rules-and-settings/discounts',
                default: '/pops/rules-and-settings/settings'
            }
            return {
                query: {
                    pop: this.$route.query.pop,
                },
                path: handleDefaultPath[handle] || handleDefaultPath.default
            }
        }
    }
}
